<template>
  <div class="sale-addon-profile fixed-draggable-dynamic-table-wrapper-height">
    <div class="sale-addon-characters">
      <draggable-dynamic-table ref="saleAddonProfileCharactersList"
                               v-if="data.length > 0"
                               :options="options"
                               :columns="columnsLabel"
                               :data="data"
                               :in-modal="false"/>
    </div>
  </div>
</template>

<script>
  import { getSaleAddon } from "../../../../../http/requests/club/salesAddons";
  import { addComma } from "../../../../../assets/js/functions";

  export default {
    name: "saleAddonProfile",
    props: {
      saleAddonsId: 0
    },
    data () {
      return {
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          id: 'saleAddonProfileCharactersList',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'price',
            i18n: 'sales.addons.profile.table.header.price',
            width: 'calc(100% / 10)',
            minWidth: 100,
          },
          {
            field: 'required',
            i18n: 'sales.addons.profile.table.header.required',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 60,
          },
          {
            field: 'name',
            i18n: 'sales.addons.profile.table.header.name',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 100,
          },
        ],
        saleAddon: {
          name: '',
          price: '',
          character: ''
        },
        actions: {
          toolbar: [
            {
              id: {name: 'editSaleAddon'},
              type: 'link',
              icon: 'EDIT',
              iconPack: 'useral',
              color: 'warning',
              permission: 'sale_addon.update'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'saleAddonActivitiesLog', params: {id: this.$route.params.id}},
              type: 'link',
              icon: 'LAST_ACTIVITIES',
              iconPack: 'useral',
              permission: 'activity_logs.show'
            },
            {
              id: 'routeBackBTN',
              route: {name: 'salesAddons'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    created () {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', ' mx-auto')
      }, 50)
      this.getSaleAddon()
    },
    methods: {
      getSaleAddon () {
        getSaleAddon(this.saleAddonsId || this.$route.params.id).then(response => {
          const saleAddon = response.data.data

          let addonPrice = [saleAddon.characters[0].price, saleAddon.characters[0].price]
          if (saleAddon.characters.length > 0) {
            saleAddon.characters.forEach(item => {
              if (item.price < addonPrice[0]) {
                addonPrice[0] = item.price
              } else if (item.price > addonPrice[1]) {
                addonPrice[1] = item.price
              }

              this.data.push({
                id: item.id || 0,
                rowNumber: this.data.length + 1,
                name: item.name || this.$t('sales.addons.labels.all'),
                required: item.required ? this.$t('sales.addons.labels.isRequired') : this.$t('sales.addons.labels.notRequired'),
                price: {
                  value: item.price || 0,
                  type: 'price'
                }
              })
            })
          }
          this.saleAddon = {
            id: saleAddon.id,
            name: saleAddon.name,
            price: addonPrice[0] === addonPrice[1] ? `${ addComma(addonPrice[0]) } ${this.$locale.currency()}` : `${ addComma(addonPrice[0]) } ${this.$locale.currency()} ${this.$t('sales.addons.labels.until')} ${ addComma(addonPrice[1]) } ${this.$locale.currency()}`,
            character: saleAddon.character || '',
            accountName: saleAddon.account && saleAddon.account.name.length > 0 ? saleAddon.account.name : ''
          }
          this.$store.dispatch('setPageTitle', this.$t('sales.addons.profile.dynamicTitle', {name: saleAddon.name}))
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sale-addon-profile {

    .sale-addon-characters {
      /*height: calc(100% - 117px);*/
      height: 100%;
      /*max-width: 75%;*/
      margin: auto;
    }
  }
</style>
